.DistribTable {

  box-sizing: border-box;
  padding: 20px 20px;
  border: 1px solid;
  position: relative;
}

.DistribTable::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 1px;
  top: 0;
  bottom: 0;
  left: 20px;
  color: white;
}

.DistribTable .search {
  display: flex;
  padding: 0 20px;
  font-size: 20px;
  align-items: center;

  /* border-right: 1px solid; */
}

.DistribTable .searchBox {
  padding: 0 20px;
  font-size: 20px;
  /* border-right: 1px solid; */
  display: flex;
  box-sizing: border-box;
  color: #fff;
}


/* Print form */
.print-form {
  border: 1px solid #000;
  font-size: 13px !important;
  /* margin: 30px 30px 0px 30px; */
  /* height : calc(100vh - 30px); */
  box-sizing: border-box;
}

.print_wapper {
  /* padding: 30px 15px; */
  box-sizing: border-box;
}

.print_wapper_order {
  padding: 30px 15px;
}

.print_wapper hr {
  color: black !important;
  opacity: 1 !important;
}

/* .print_wapper .paper {
  padding: 30px 15px;
} */
.print-form h1.title {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  padding: 6px 0;
}

.print-info {
  display: grid;
  border-top: 1px solid #000;
  min-height: 30px;
  grid-template-columns: repeat(4, auto max-content);
}

.print-info p.title {
  background-color: #839EBE;
  font-size: 13px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  min-width: max-content;
}

.print-info p.title:nth-of-type(1),
.print-info p.title:nth-of-type(9) {
  border-left: 0;
}

.print-info p {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  height: 100%;
  padding: 0 5px;
  font-size: 13px;
  word-break: keep-all;
  margin: 0;
}

.print-form hr {
  grid-column: 1 / -1;
  border: 0;
  border-top: 1px solid #000;
  margin: 0;
}

.print-info .half {
  grid-column: span 3;
  font-size: 13px;
  display: flex;
}

.print-info .half+.half {
  border-left: 1px solid #000;
}

.print-info .full {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 140px auto;
}

.print-info .full>p:nth-child(1n) {
  border-left: 0;
}

.print-info .full>ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 0px;
}

.print-info .full>ul li {
  display: flex;
  align-items: center;
}

.print-info .full>ul li p {
  height: 65px;
  padding: 0 10px;
  border-right: 1px solid #000;
}

.print-info .full>ul li p.countBox {
  padding: 0 10px;
}

table.print-detail {
  border-top: 1px solid #000;
  width: 100%;
}

table.print-detail th {
  font-weight: 700;
  border-left: 0;
  border-bottom: 1px solid #000;
  line-height: 45px;
  background-color: #839EBE;
  font-size: 13px;
  border-right: 1px solid #000;
  min-width: max-content;
}

table.print-detail td {
  text-align: center;
  min-height: 100px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

table.print-detail tr:last-child td {
  border-bottom: 0;
}

table.print-detail td:last-child {
  border-right: 0;
}

table.print-detail th:last-child {
  border-right: 0;
}

table.print-detail .pImg img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
}

table.print-detail .pImg {
  display: inline-flex;
  width: 100px;
  vertical-align: middle;
}

.listBtn02.print button {
  font-size: var(--fz_20);
}

/* Style dashboard */
.dashboard-wrapper {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.dashboard-item {
  width: calc((100% - 96px) / 5);
}





/* print event */

.paper .content p {
  margin-bottom: 0;
}

.paper .content .table {
  margin: 0;
}

.paper .content .row hr {
  margin: 0;
}

.paper .content ul {
  margin: 0;
}


.paper {
  width: 210mm;
  min-height: 297mm;
  padding: 10mm;
  /* set contents area */
  margin: 10mm auto;
  /* border-radius: 5px; */
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.paper .content {
  border: 1px solid #000;
  overflow: hidden;
}

.paper_padding {
  padding-bottom: 30px;
}

/* .content {
  padding: 0;
  border: 1px #888 dotted;
  height: 257mm;
} */

.paper .content .header {
  height: 50px;
  display: grid;
  place-items: center;
}

.paper .content .header h1 {
  font-size: 25px;
  font-weight: 700;
}

.paper .content .row {
  display: grid;
  border-top: 1px solid #000;
  min-height: 30px;
}

.paper .content .row hr {
  grid-column: 1/-1;
  border: 0;
  border-top: 1px solid #000;
}

.paper .content p {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  height: 100%;
  padding: 0 5px;
  font-size: 13px;
  word-break: keep-all;
  flex-wrap: wrap;
  text-overflow: ellipsis;
}

.paper .content .title {
  /* background-color: #839EBE; */
  font-size: 13px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  min-width: max-content;
}

.paper .content .row hr+.title {
  border-left: 0;
}

.paper .content>.row>.title:nth-of-type(1),
.paper .content>.row>.title:nth-of-type(9) {
  border-left: 0;
}

.paper .content p.half {
  grid-column: span 3;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 13px;
}

.paper .content p.full {
  align-items: flex-start;
  justify-content: flex-start;
}

.paper .content p:not(.title) {
  align-items: flex-start;
  justify-content: flex-start;
}

.paper .content .half+.half {
  border-left: 1px solid #000;
}

.paper .content .half p:nth-child(1),
.paper .content .full p:nth-child(1) {
  border-left: 0;
}

.paper .content p.full {
  grid-column: span 7;
  justify-content: start;
  font-size: 13px;
  /* max-height: 1em;
  line-height: 1;
  overflow: hidden; */
}

.paper .content p.memo-print {
  max-height: 1em;
  line-height: 1;
  overflow: hidden;
}

.paper .content .full>p:nth-child(1n) {
  border-left: 0;
}

.paper .content ul {
  display: flex;
  align-items: center;
  grid-column: span 7;
}

.paper .content ul>li {
  display: flex;
  align-items: center;
}

.paper .content ul>li>p {
  height: 65px;
  padding: 0 10px;
  border-right: 1px solid #000;
}

.paper .content ul>li>p.countBox {
  padding: 0 10px;
  min-width: max-content;
  margin-left: 45px;
}

.paper .content ul>li:has(em) {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.paper .content ul>li>em {
  font-style: normal;
  font-size: 13px;
  border-bottom: 1px solid #000;
  width: 100%;
  text-align: center;
}

.paper .content ul>li>em+span {
  font-size: 13px;
  margin: auto 0;
}

/* table */
.paper .content .table {
  display: grid;
  grid-template-columns: repeat(9, auto);
  font-size: 13px;
  border-top: 1px solid #000;
}

.paper .content .table>.title {
  font-weight: 700;
  border-left: 0;
  border-bottom: 1px solid #000;
  height: 45px;
}

.paper .content .table>p:nth-child(9n) {
  border-right: 0 !important;
}

.paper .content .table>p:not(.title) {
  min-height: 100px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.paper .content .table>p:not(.title):nth-last-child(1),
.paper .content .table>p:not(.title):nth-last-child(2),
.paper .content .table>p:not(.title):nth-last-child(3),
.paper .content .table>p:not(.title):nth-last-child(4),
.paper .content .table>p:not(.title):nth-last-child(5),
.paper .content .table>p:not(.title):nth-last-child(6),
.paper .content .table>p:not(.title):nth-last-child(7),
.paper .content .table>p:not(.title):nth-last-child(8),
.paper .content .table>p:not(.title):nth-last-child(9) {
  border-bottom: 0;
}

.paper .content .table>p:has(img) {
  padding: 0;
}

.paper .content .table>p>img {
  width: 100px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
}

.event-item {
  position: relative;
}

.event-detail-popup {
  display: none;
  border: 1px solid rgb(222, 226, 230);
  background-color: #fff;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 9;
}

.event-item:hover .event-detail-popup {
  display: block;
}

.event-detail-popup .copy-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 13px;
  padding: 3px 10px;
}

.event-detail-popup h6 {
  font-weight: 700;
  text-align: center;
  margin: 15px auto;
  color: #000;
}

.event-detail-popup table {
  min-width: 400px;
  margin-bottom: 0;
}

.event-detail-popup table th {
  border-left: 0;
  text-align: center;
  width: 120px;
}

.event-detail-popup table td {
  border-right: 0;
  width: auto;
}

.event-detail-popup table tr:last-child th,
.event-detail-popup table tr:last-child td {
  border-bottom: 0;
}

.event-schedule>tbody>tr.saturday>td {
  background: #87B6D4;
  color: #fff;
  box-shadow: none;
}

.event-schedule>tbody>tr.sunday>td {
  background: #E98D9E;
  color: #fff;
  box-shadow: none;
}

span.require {
  color: red;
  font-size: 13px;
}

.mask.blocker.current {
  z-index: 9999;
}

.confirm-modal.modal {
  max-width: unset;
  width: auto;
  z-index: 1055;
  background: transparent;
  position: fixed;
  inset: 0;
}

.department-btn {
  position: relative;
}

.department-btn .btn-close {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 13px;
  opacity: 1;
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.modal {
  max-width: 650px;
}

@page {
  size: A4;
  margin: 0;
}

@media print {

  html,
  body {
    width: 210mm;
    min-height: 297mm;
    background: #fff;
  }

  .paper {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

.paper .content p:not(.title) {
  align-items: center;
  justify-content: flex-start;
}

p.full {
  white-space: break-spaces;
  word-break: break-all !important;
  text-align: left;
  width: 100%;
}

.print_wapper .table p {
  justify-content: center !important;
  page-break-inside: avoid;
}

.paper .content ul>li>em {
  margin-left: 8px;
  width: 104%;
}

.order-by-product {
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 10px;
  width: 60px;
}

.active {
  color: rgb(99, 188, 239);
  /* Màu xanh trời */
}

.inactive {
  color: #7a7979;
  /* Màu xám */
}

.haveProduct {
  background-color: #0d6efd26;
  /* Màu xám */
}

.ra_box .raInner {
  width: 100%;
}

.ra_box img {
  width: 100%;
}

.ra_box .raInner img {
  object-fit: contain;
}

.ra_box02 .raInner {
  display: flex;
}

.continerImage .contentImg {
  flex: 0 0 var(--slide-size);
  max-width: var(--slide-size);
}

.ra_box p b {
  color: #666;
  display: block;
  text-align: center;
  margin: 5px auto;
}

.contentImg label .imageP {
  display: block;
}

.ra_box {
  display: flex;
  float: left;
  box-sizing: border-box;
  width: 100%;
}

.box-slide-img {
  --slide-size: 40%;
}

.ra_box02 {
  overflow-x: scroll;
  display: block;
}

@media screen and (max-width: 1500px) {
  .box-slide-img {
    /* --slide-size: 60% !important; */
  }
}

@media screen and (max-width: 1500px) {
  .ra_box02 {
    overflow-x: scroll;
    display: block;
  }
}

.item-print {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2px;
  position: relative;
  font-size: 13px;
}

.item-print-highlight {
  background-color: #DFEAF5;
}

.item-print-left {
  border-left: 1px solid #000;
}

.cost-print {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.item-print-cost {
  border-right: 1px dotted #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.border-right-none {
  border-right: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
  height: 100%;
}

.border-print-bottom-none {
  border-bottom: none !important;
}

.border-top-none {
  border-bottom: none !important;
}


.border-left-item {
  border-left: 1px solid #000;
}

.border-top-item {
  border-top: 1px solid #000;
}

.won-money-icon {
  position: absolute;
  left: 5px;
}

.paper .content .title-event {
  display: flex;
  text-align: center;
  width: 100%;
  border-right: none;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-bottom-width: 0;
  border-bottom: 1px solid #000;
  min-width: auto !important;
}

.title-tr {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title-tr>th {
  padding: 8px 2px !important;
}

.paper .content .table-event {
  display: flex;
  grid-template-columns: none;
  flex-direction: row;
}

.border-print-top {
  border-top: 1px solid #000 !important;
  border-bottom: none !important;
}

.table-event-statement,
.table-event-statement th,
.table-event-statement td {
  border: 1px solid #000;
  border-collapse: collapse;
  padding: 4px 2px;
  font-size: 13px;
  color: #000;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
}

.no-border {
  border: none;
}

.border-white-print {
  border: 1px solid #fff !important;
}

.border-white-top {
  border-top: 1px solid #fff;
}

.border-white-top-2 {
  border-top: 2px solid #fff;
}

.border-white-bottom {
  border-bottom: 1px solid #fff;
}

.border-top-0 {
  border-top: 0 !important;
}

.group-suplier {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media print {
  .item-print-media {
    border-bottom: 1px solid #000 !important;
    /* Bắt buộc hiển thị border */

    border-collapse: collapse !important;
  }
}